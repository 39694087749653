.Video {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid gray;

  &.alreadyExists {
    position: relative;

    &::before {
      content: 'Error: a file with the same name was already uploaded';
      display: flex;
      left: 100px;
      position: absolute;
      z-index: 22;
      font-size: 18px;
      color: white;
    }
    &::after {
      z-index: 0;
      position: absolute;
      content: '';
      width: 100%;
      height: 100%;
      background-color: black;
      opacity: 0.6;
    }
  }
  .preview {
    min-width: 90px;
    min-height: 75px;
    max-width: 90px;
    max-height: 75px;
    object-fit: cover;
    margin-right: 15px;
  }
  .details {
    width: 100%;

    progress {
      width: 100%;
    }
    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      h1 {
        margin: 0;
        font-size: 16px;
        color: gray;
      }
      .size {
        margin-right: auto;
        margin-left: 15px;
      }
      .closeIcon {
        cursor: pointer;
      }
    }
    .uploadStatus {
      display: flex;
      justify-content: space-between;
    }
  }
}
