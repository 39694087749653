.modalDialog {
  max-width: 400px !important;
}

.createButton {
  margin-left: auto;
}

.inputContainer {
  label {
    display: flex;
    flex-direction: column;
    font-style: italic;

    input {
      width: 80%;
      height: 30px;
      padding-left: 10px;
      padding-right: 10px;
      border: 2px solid lightgray;
      border-radius: 3px;
      &:focus {
        outline: none;
      }
    }
  }
}
