.entities-item {
  display: flex;
  flex-direction: column;
  width: 100px;
  border: 1px solid black;
  margin: 20px 0px 0px 20px;
  box-sizing: border-box;
}

.entities-label{}

.entities-img {
  display: block;
  width: 100px;
  height: 100px;
}

.entities-name{
  margin-bottom: 0;
  text-align: center;
}

.entities-checkbox {
  display: block;
  margin: 0 auto;
}