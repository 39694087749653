.Filters {
  margin-bottom: 30px;
  h3 {
    margin-bottom: 15px;
  }
  .selectors {
    display: flex;
    .selector {
      display: flex;
      align-items: center;
      margin-bottom: 25px;
      select {
        width: 125px;
        height: 24px;
      }
      label {
        margin-right: 30px;
      }

      &:first-child {
        margin-right: 40px;
      }

      &:last-child {
        margin-left: auto;
      }
    }
  }
  .buttonContainer {
    display: flex;
    flex-direction: row-reverse;
  }
}
