.Video {
  position: relative;
  margin-bottom: 2rem;
  cursor: pointer;

  video {
    aspect-ratio: 16 / 9;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .overlay {
    position: absolute;
    bottom: 24px;
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 5px;
    color: white;
    background-color: rgba(0, 0, 0, 0.2);

    p {
      font-size: 12px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      margin: 0;
    }
  }

  .footer {
    cursor: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    span {
      color: black;
    }
    .icon {
      margin-left: 5px;
      cursor: pointer;
    }
  }
}
