.buttonContainer {
  display: flex;
  flex-direction: row-reverse;
  margin-top: 15px;

  button {
    &:last-child {
      margin-right: 15px;
    }
  }
}

.entities-box{
  display: flex;
}

.entities-list{
  display: flex;
  list-style: none;
  padding: 0;
  margin-left: 5px;
  margin-bottom: 0;
}

.entities-item:not(:last-child){
  margin-right: 5px;
  &::after{
    display: inline;
    content: ',';
  }
}
