.FileOrDirectoryUpload {
  color: gray;
  .header {
    h1 {
      color: gray;
    }
    /* &::after {
      content: '';
      position: absolute;
      top: 60px;
      display: block;
      height: 1px;
      width: 94%;
      background-color: lightgray;
    } */
  }

  .mainContainer {
    display: flex;
    .leftColumn {
      display: flex;
      flex-direction: column;
      min-width: 40%;
    }
    .rightColumn {
      min-width: 60%;
      max-height: 490px;
      overflow-y: auto;
      h2 {
        text-align: center;
        color: gray;
      }
    }
    .dropzoneContainer {
      outline: none;
      margin-right: auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 50px;
      .dropzone {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 350px;
        height: 300px;
        margin-bottom: 20px;
        background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='10' ry='10' stroke='%23c7c7c7' stroke-width='4' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
        border-radius: 10px;

        p {
          font-size: 18px;
          color: gray;
        }

        .arrowIcon {
          margin-bottom: 10px;
        }
      }

      button {
        width: 144px;
        height: 38px;
        color: white;
        font-size: 16px;
        background-color: #04c8c8;
        border: none;
        border-radius: 5px;

        &:hover,
        &:active {
          background-color: #00afaf;
        }
      }
    }

    .options {
      width: 350px;
      font-size: 14px;
      color: gray;
      .analyticsSwitcher,
      .filePathsSwitcher {
        display: flex;
        align-items: center;

        label {
          margin-right: 57px;
        }
      }
      .filePathsSwitcher {
        margin-bottom: 25px;
        label {
          margin-right: 50px;
        }
      }
      .filePath {
        margin-bottom: 25px;
        input {
          width: 100%;
          padding-left: 10px;
          padding-right: 10px;
        }
      }
      .folderSelector {
        display: flex;
        align-items: center;
        margin-bottom: 25px;
        select {
          width: 125px;
          height: 24px;
        }
        label {
          margin-right: 30px;
        }
      }
    }
  }
  .closeButton {
    margin-left: auto;
  }
}

@media (max-width: 1200px) {
  .FileOrDirectoryUpload {
    .mainContainer {
      align-items: center;
      flex-direction: column;

      .leftColumn {
        margin-bottom: 55px !important;
      }
      .rightColumn {
        max-height: none;
      }
    }
  }
}
