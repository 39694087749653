.Folder {
  .folderIcon {
    width: 27px !important;
    height: 25px !important;
  }

  &.active {
    background-color: rgb(245, 241, 241);
    border-radius: 5px;
  }

  .folderName {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .nameInput {
      width: 80%;
    }
    .title {
      display: block;
      max-width: 170px;
      overflow: hidden;
      text-overflow: ellipsis;
      cursor: pointer;
      white-space: nowrap;
    }
    .actionIcons {
      span {
        display: flex;
      }
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 35px;
      .actionIcon {
        cursor: pointer;
      }
    }
  }
}

@media only screen and (max-width: 992px) {
  .Folder {
    .folderName {
      span {
        max-width: 80px !important;
      }
    }
  }
}
