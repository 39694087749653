.Entities {
  h3 {
    margin-bottom: 35px;
  }

  .entitiesList {
    display: flex;
    flex-wrap: wrap;
    overflow: scroll;
    justify-content: center;
    margin: -20px 0px 20px -20px;
    padding: 0px 0px 20px 0px;
    list-style: none;
    height: 200px;
    background-color: #f7f6f6;
  }

  .buttonContainer {
    display: flex;
    flex-direction: row-reverse;
  }
}
