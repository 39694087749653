.Header {
  .headerContainer {
    max-width: 100%;
    padding-left: 110px;
    .logo {
      position: relative;
      left: -12px;
    }

    .mobileTitle {
      position: absolute;
      top: 20px;
      left: 100px;
    }
  }
}

@media only screen and (max-width: 992px) {
  .Header {
    .headerContainer {
      padding-left: 15px;
    }
  }
}
